import { ErrorBar, FloatingLabel, FieldWrapper, TextArea, ErrorText } from "./FieldStyles"

// Text Area Field
const TextAreaField = ({ input, meta, ...props }) => (
    <FieldWrapper>
        <FloatingLabel htmlFor={props.label} float={(meta.active || meta.autofilled || meta.dirty) }>{props.label}</FloatingLabel>
        <TextArea  {...input} id={props.label} aria-label={props.label} type={props.type} autoComplete={props.autoComplete} rows="10"/>
        <ErrorBar active={meta.error && meta.touched && !meta.active}/>
        {
            !props.hideErrorText && (
                meta.error && meta.touched && !meta.active && <ErrorText>{meta.error}</ErrorText>
            )
        }
    </FieldWrapper>
)   


export default TextAreaField;