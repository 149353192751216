import { ErrorBar, FloatingLabel, FieldWrapper, Select, ErrorText } from "./FieldStyles"

// Simple dropdown select field
const SelectField = ({ input, meta, ...props }) => (
    <FieldWrapper>
        <FloatingLabel htmlFor={props.label} float={(      (meta.initial && !meta.dirty) || meta.active || meta.autofilled || meta.dirty) }>{props.label}</FloatingLabel>
        <Select {...input} id={props.label} aria-label={props.label} disabled={props.disabled}>
            {
                !props.removeBlankOption && (
                    <option></option>
                )
            } 
            {props.options.map(option => (
                <option key={option.value} value={option.value}>
                    {option.text ? option.text : option.value}
                </option>
            ))}
        </Select>
        <ErrorBar active={meta.error && meta.touched && !meta.active}/>
        {
            !props.hideErrorText && (
                meta.error && meta.touched && !meta.active && <ErrorText>{meta.error}</ErrorText>
            )
        } 
    </FieldWrapper>
)   


export default SelectField;