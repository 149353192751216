import React from 'react'
import { ErrorBar, FloatingLabel, FieldWrapper, Input, ErrorText, OptionalField } from "./FieldStyles"

// Simple plain text field for email, address, etc
const PlainTextField = ({ input, meta, ...props }) => (
    <FieldWrapper stripeField={props.stripeField}>
        <FloatingLabel 
            htmlFor={props.label}
            float={(
                (meta.initial && !meta.dirty) ||
                meta.active ||
                meta.autofilled ||
                meta.dirty 
            )}>
            {props.label} {props.showOptional && <OptionalField>(optional)</OptionalField>}
        </FloatingLabel>
        <Input {...input} stripeField={props.stripeField} id={props.htmlID} inputMode={props.inputMode} min="1" aria-label={props.label} type={props.htmlType} autoComplete={props.autoComplete} />
        <ErrorBar active={meta.error && meta.touched && !meta.active}/>
        {
            !props.hideErrorText && (
                meta.error && meta.touched && !meta.active && <ErrorText>{meta.error}</ErrorText>
            )
        }
    </FieldWrapper>
)   


export default PlainTextField;