import styled, { css } from "styled-components";

// Univeral input styles
// Contains the label, error bar, and input
export const FieldWrapper = styled.div`
  position: relative;
  background: white;
  border: 2px solid #ececec;
  display: block;
  transition: all 0.1s ease;
  border-radius: ${(props) => props.theme.borderRadius};
  :focus-within {
    border: 2px solid;
    border-color: ${(props) =>
      props.stripeField ? "#d6d6d6" : props.theme.colors.primary};
  }
`;

// All inputs will generall have this exact styling inherited in inther component
export const BaseInputStyles = css`
  outline: none;
  line-height: 21px;
  color: ${(props) => props.theme.colors.black};
  font-size: 1.8rem;
  display: block;
  width: 100%;
  font-weight: 500;
  background: white;
  border-radius: ${(props) => props.theme.borderRadius};
  border: none;
  padding: 35px 19px 15px 19px;
  -webkit-appearance: none;
  box-sizing: border-box;
  ::placeholder {
    color: white;
  }
`;

export const Select = styled.select`
  ${BaseInputStyles};
  background: white;
  background: url("https://cdn.cleancult.com/next/images/misc/Select.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: right;
`;

// If the field is being used for stripe .. ie Postal Code, then turn to Menlo for font
export const Input = styled.input`
  ${BaseInputStyles}
  font-family: ${(props) => props.stripeField && "Menlo !important"};
  font-weight: ${(props) => props.stripeField && "800"};
  cursor: ${(props) => props.disabled && "not-allowed"};
`;

// Lablel that jumps up and back down based on the input focus
export const FloatingLabel = styled.label`
  position: absolute;
  top: ${(props) => (props.float ? "18px" : "30px")};
  left: 20px;
  pointer-events: none;
  opacity: 0.7;
  transition: all 0.1s, left 0.1s, font-size 0.1s;
  color: ${(props) => props.theme.colors.black};
  z-index: 9;
  font-family: ${(props) => props.theme.fonts.paragraph};
  font-weight: 600;
  font-size: ${(props) => (props.float ? "13.5px" : "17px")};
`;

export const TextArea = styled.textarea`
  ${BaseInputStyles};
  resize: none;
`;

// Error bar that raises and lowers based on if there is an error the field
export const ErrorBar = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  transition: all 0.1s ease;
  width: 4.5px;
  border-radius: 7px;
  height: ${(props) => (props.active ? "100%" : "0%")};
  background: ${(props) => props.theme.colors.danger};
`;

// Small error text
export const ErrorText = styled.p`
  color: ${(props) => props.theme.colors.danger};
  margin: 0px 0px 10px 20px;
  font-weight: 500;
  text-align: left;
`;

export const OptionalField = styled.span`
  color: #868686;
  font-size: 1.2rem;
  opacity: 0.7;
`;
